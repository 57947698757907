//let SMPGameplay = require('./SMPGameplay');
// const questIdReference = {
// "7fd2600c-0356-478e-8c15-b073e8a421f8":"GAME_FULL_TEAM",
// "d37ad18b-a766-4e81-806c-bf9fc6cb55a3":"REACH_STAGE",
// "4abe7667-b905-4c3b-adbd-fc6ec9fb4b51":"COMPLETE_TH_STAGE",
// "0082105a-c584-4e4f-a0ff-b34099cb76b9":"COMPLETE_LEVEL",
// "bb1e71bd-2bf4-4e05-82c4-a66854bfa292":"BEAT_LEVEL",
// "aedbc674-8955-42ed-8d2b-3495859a3a24":"HERO_FULL_TEAM",
// "a404f8fe-b91a-4cd6-886d-7de4d19df84b":"HIRE_HERO",
// "e621a18f-9c88-43d9-84d7-33e3207ab726":"UPDATE_HERO_TO_LEVEL",
// "6d24e5ad-94cd-4724-8a77-2bd50b0bd558":"PROMOTE_HERO_TO_BRONZE",
// "716e4867-c04d-4a7a-bcf9-760be9f13489":"PROMOTE_HERO_TO_SILVER",
// "cfda62be-cdd0-4878-b360-057ab83d14d7":"PROMOTE_HERO_TO_GOLD",
// "9ae51f8e-17b6-49cb-98e5-1cd63ccb1233":"PROMOTE_HERO_TO_PLATINIUM",
// "f3aeec3d-4259-4ddd-bd6a-0d98e25579b5":"PROMOTE_N_HERO_TO_BRONZE",
// "096a8a3f-3905-4775-822f-3d11de70a79a":"PROMOTE_N_HERO_TO_SILVER",
// "e238eb39-85d5-46a1-a77b-7e369e89f52c":"PROMOTE_N_HERO_TO_GOLD",
// "6d3853de-4122-453e-83df-1fddf9224ce3":"PROMOTE_N_HERO_TO_PLATINIUM",
// "5f16bc8c-3bce-4bc3-a338-fc07975c6c7b":"HAKNURA_REACH_RANK",
// "bab1904b-67d4-4beb-8c3c-1cac081a4a80":"KASOR_REACH_RANK",
// "1fd33a14-2d22-4ed4-9815-f43963e32146":"MEORA_REACH_RANK",
// "7e400473-425d-4d0b-84ed-1fd5ff39a618":"REACHNY_REACH_RANK",
// "e45e0d36-5f01-4224-a481-b5b6441f8aef":"VISALABOT_REACH_RANK",
// "ca7fafca-59e3-46e8-b6db-7d01a253beac":"TIDA_REACH_RANK",
// "de752342-d632-4746-8e15-a674583a66d2":"SOKNARIKA_REACH_RANK",
// "4df490f4-6804-4d63-8ae1-2d1f627f1aae":"MOTHARO_REACH_RANK",
// "16139f5b-56ad-414a-8bd6-9ceaabfbd19e":"ALL_HERO_REACH_RANK",
// "168344b3-1065-4274-aec9-4f7281c6d811":"LEVEL_UP_HAKNURA_N_TIME",
// "2b919ffa-f8c6-4fd9-8187-6585a548fcf8":"LEVEL_UP_KASOR_N_TIME",
// "102b77a3-eb83-4c9b-a0b4-30c12a9dd936":"LEVEL_UP_MEORA_N_TIME",
// "23773ce9-6cff-463d-995e-0e9c5e57dc86":"LEVEL_UP_REACHNY_N_TIME",
// "8ea1e883-14e9-4521-ba5a-0ea6002ba28e":"LEVEL_UP_VISALABOT_N_TIME",
// "a18932fb-10ff-4670-9af6-672b94a44f98":"LEVEL_UP_TIDA_N_TIME",
// "2f7a46d6-9be1-4105-a8d8-dd0c2a857a99":"LEVEL_UP_SOKNARIKA_N_TIME",
// "a66fb248-63a0-4370-92dc-beb1ccdfb08e":"LEVEL_UP_MOTHARO_N_TIME",
// "dddde071-2539-4375-91ef-cce859569061":"LEVEL_UP_HERO_N_TIME",
// "fa80fe57-b115-4c9e-a2fc-a8bb85d530cf":"UPGRADE_N_HERO_TO_LEVEL",
// "0d50bd03-0322-40f4-9e4a-cd9bae167c90":"UPGRADE_ALL_HERO_TO_LEVEL",
// "667133d4-201d-465c-adb8-e7432a6a4e96":"SENA_REACH_RANK",
// "6e2af4d0-cb7e-44bf-823c-e16bcaeb26c7":"VORANA_REACH_RANK",
// "1de5cb59-7338-4c6c-b1fa-da2f5f0c419c":"CHAOCHEUM_REACH_RANK",
// "040c639d-c1f1-4751-9244-dab4662f73f6":"KAKEI_REACH_RANK",
// "f69495bd-65b8-4d8e-8188-90bbb06811fd":"REACHBOTH_REACH_RANK",
// "f731752e-2e93-4c77-b85b-49028a6140fc":"KOLA_REACH_RANK",
// "ec323299-8658-4834-b350-1896c342583a":"CHHORDA_REACH_RANK",
// "7f8d8d1e-7a52-44aa-8048-0190557cc885":"MALA_REACH_RANK",
// "84adb139-7e4a-4c0c-9e04-e58659088574":"BOTOM_REACH_RANK",
// "cae0fe21-975a-41d3-aa00-4a443d1628e2":"ROMSAISORK_REACH_RANK",
// "ec9ec84d-8c92-4e6f-a426-822427b9fa0f":"KAMOV_REACH_RANK",
// "a0dca552-450b-42ce-9704-4ba802ee117d":"ANGULIMEAR_REACH_RANK",
// "5ae4abec-f5ad-40ab-8a38-ffeec4a76157":"MACHAR_REACH_RANK",
// "a313ea1e-a2bb-4dbc-9045-c0f2fbc1178a":"NEANGPHAN_REACH_RANK",
// "dc0a1fe4-834e-4082-a3ca-2605a857d5bf":"TIDA_REACH_RANK",
// "30fc67ea-6cc0-4b55-b77f-ad09b9c347b5":"CHEY_REACH_RANK",
// "c228b3f5-0f7b-4176-8357-bd1873acec48":"ANGKTEP_REACH_RANK",
// "6271fa8c-9805-4008-aca4-ff739d9993bc":"MARA_REACH_RANK",
// "8491c98c-724a-4443-896b-750e0036e4d5":"MEURA_REACH_RANK",
// "3642ac18-74c6-4613-958e-800ce75fa803":"ANGKPISETH_REACH_RANK",
// "b530603b-e175-45d9-811b-da40edfb2933":"UPDATE_SUPPORT_TO_LEVEL",
// "976160fe-4a3b-44c9-b100-eea21a3dc266":"LEVEL_UP_SENA_N_TIME",
// "5f523795-050a-41e0-9679-226bf7300a89":"LEVEL_UP_VORANA_N_TIME",
// "3536f22c-7959-4305-8d10-89c5d047b0ea":"LEVEL_UP_CHAOCHEUM_N_TIME",
// "3aa8b5d7-fea2-4369-b840-34ddcf44e81b":"LEVEL_UP_KAKEI_N_TIME",
// "4251d117-332b-438d-a0f6-7e8dd482ab3a":"LEVEL_UP_REACHBOTH_N_TIME",
// "5e0bda88-fb31-4d88-ba9d-b1943d789f01":"LEVEL_UP_KOLA_N_TIME",
// "c322d60f-f09c-4390-9456-29dd5fdc8990":"LEVEL_UP_CHHORDA_N_TIME",
// "682a15b5-21de-4f55-b5cb-cab9ba26a5cc":"LEVEL_UP_MALA_N_TIME",
// "d0ef1c4b-8ab3-4a15-9a5b-a665e107b8e2":"LEVEL_UP_BOTOM_N_TIME",
// "d1dee3f1-6e87-4719-9da0-2f8bb5c99eee":"LEVEL_UP_ROMSAISORK_N_TIME",
// "141c3447-99dc-4be8-9d8e-df78575e39a4":"LEVEL_UP_KAMOV_N_TIME",
// "92b613f9-3561-4f21-b369-da50327de099":"LEVEL_UP_ANGULIMEAR_N_TIME",
// "20a0ad41-14da-4f88-8f9b-8bbb07f1a5f6":"LEVEL_UP_MACHAR_N_TIME",
// "541a639d-613e-4426-a0a8-0c761e98b5a1":"LEVEL_UP_NEANGPHAN_N_TIME",
// "14e5445f-d09c-429b-81e2-42454f760649":"LEVEL_UP_TIDA_N_TIME",
// "8154be8e-3e9b-4f60-86b0-6fe2fbcc76e3":"LEVEL_UP_CHEY_N_TIME",
// "bbc1079b-b5e9-42b7-bd3d-b04096fcaf25":"LEVEL_UP_ANGKTEP_N_TIME",
// "32adae30-4c91-4994-98aa-e4de25202516":"LEVEL_UP_MARA_N_TIME",
// "d5017f3a-0673-4a6b-8db8-fa3f23149fba":"LEVEL_UP_MEURA_N_TIME",
// "6f13b54c-07d5-4f1c-a8ac-02980b19e7f2":"LEVEL_UP_ANGKPISETH_N_TIME",
// "84ad457d-0efe-464a-94b2-ddfcc6556161":"LEVEL_UP_SUPPORT_N_TIME",
// "09fddfcb-abcc-4edf-b39f-6b6d11691442":"HIRE_N_SUPPORT",
// "6fb95e9d-d285-40be-8236-04ae0168c312":"FULL_FLYING_SUPPORT",
// "3c108949-7294-436a-8ab7-ef47f75295b5":"FULL_ACTIVE_SUPPORT",
// "022b940d-3487-405a-be95-5925ff843eca":"FULL_SUPPORT",
// "a51cd2d5-736c-403b-9664-062fa817176c":"SENA_EVOLVE_N_TIME",
// "08e6d95f-3177-4b65-b640-62362acea085":"VORANA_EVOLVE_N_TIME",
// "5f55aa26-4e88-444f-bcc4-058c40656c92":"CHAOCHEUM_EVOLVE_N_TIME",
// "a2105ca1-026f-49ec-8a62-9dd8502d2c82":"KAKEI_EVOLVE_N_TIME",
// "829ad14b-aeb8-4939-b77e-4d74bf96f4b0":"REACHBOTH_EVOLVE_N_TIME",
// "1e3e3ba8-7b8c-46fe-ac05-11f0139e8c73":"KOLA_EVOLVE_N_TIME",
// "5d75b10c-0733-4ef0-9d81-3e1c9a789471":"CHHORDA_EVOLVE_N_TIME",
// "7807210d-96a0-4885-b066-663412ca01a1":"MALA_EVOLVE_N_TIME",
// "fb77fb6d-0425-4bb9-b7d5-09e84b99e4d5":"BOTOM_EVOLVE_N_TIME",
// "08543dba-7a15-4ca1-bcb9-9e895a830c84":"ROMSAISORK_EVOLVE_N_TIME",
// "1a6ba50c-88ad-4241-b7df-efb6a3031cc1":"KAMOV_EVOLVE_N_TIME",
// "bc4dcfe1-4eb5-4200-a5c6-c63164b61301":"ANGULIMEAR_EVOLVE_N_TIME",
// "05b0e28f-8b05-4072-98e4-a24e20648d96":"MACHAR_EVOLVE_N_TIME",
// "f6735d32-6119-4fd4-8cd1-19c7c373ed9b":"NEANGPHAN_EVOLVE_N_TIME",
// "6ba9d41f-af90-4a2f-855e-cae0484272d4":"TIDA_EVOLVE_N_TIME",
// "5fd56794-1d0b-4a42-bbdf-316bbe5dc0c3":"CHEY_EVOLVE_N_TIME",
// "16f4f855-5240-4e9a-b612-280bc47c96d2":"ANGKTEP_EVOLVE_N_TIME",
// "3ecd670d-26ea-4124-bf7b-f9a61c8807a6":"MARA_EVOLVE_N_TIME",
// "fcf3d37e-6fce-41f8-97fe-849a84467bb0":"MEURA_EVOLVE_N_TIME",
// "9a0d77b1-824b-493f-ba8b-d11b5284cfae":"ANGKPISETH_EVOLVE_N_TIME",
// "94cb4626-d543-4bfc-8d99-b36982d18d8a":"ALL_SUPPORT_REACH_EVOLVE",
// "e5be8224-b8c7-453f-8aaa-d038c5f4272f":"UNLOCK_N_SUPPORT_SKILL",
// "7b0429dd-bc9b-413e-8d2f-b7b65eeb27ed":"OBTAIN_N_POWER_UP_AND_USE",
// "a09e08a5-8c27-4ff7-a80c-ea6d639b72c3":"UNLOCK_N_HERO_POWER_UP",
// "63209fff-2251-42b7-aed3-e1bc6e7d8147":"OBTAIN_FIRST_POWER_UP",
// "df080cb5-1e84-4d52-9fef-710b969bcc62":"TRAIN_N_HERO_POWER_UP",
// "dec66176-80dc-4182-a144-b0db6448ce1f":"UPGRADE_POWER_UP_N_TIME",
// "2ebfdf17-1349-4b72-9887-0aa56fc7ddd8":"COLLECT_N_GOLD",
// "960034f4-a1fc-4f0a-94e9-5d0773db3c43":"COLLECT_N_GOLD_IN_DURATION",
// "dd1a32bc-35d4-46ca-8dab-0ec70efbec46":"COLLECT_N_GOLD_AND_UNLOCK_N_SUPPORT",
// "cf1077ec-0c7c-4635-8ec7-5da9eaffa433":"COLLECT_N_GOLD_AND_UNLOCK_N_HERO",
// "bd3303aa-ac0a-4fb6-9a77-c2963d9fc7a9":"TAP_N_TIME",
// "8f4df756-f24c-4150-b7e0-790010e885f8":"TAP_N_TIME_IN_DURATION",
// "7acc047d-9fef-47a3-a8b9-a0d7e8d4e4a0":"FIRST_TAP",
// "efc8fd67-9d97-439b-90ba-c82547114056":"KILL_N_GHOST",
// "3e092d42-46da-451a-b370-9d361315f609":"DEFEAT_N_BOSS",
// "083d1d66-8818-4508-9402-dca739f86983":"KILL_N_TIME_BOSS",
// "4afe97aa-f82a-4301-8be7-de7fc9d7ec9d":"COLLECT_FIRST_BOSS_CROWN",
// "7b3a74b1-f995-48f1-a691-3b7dfd917e3a":"COLLECT_N_BOSS_CROWN",
// "3906e1c5-c8f9-4506-8c07-4707bc733e8e":"KILL_N_TIME_BIG_BOSS",
// "47f01240-c954-4f0d-9af9-51074faf86cb":"DEFEAT_N_BOSS_OF_REVENGE",
// "f609c95d-0834-487f-9145-9777322698f6":"OPEN_CHEST_N_TIME",
// "aa11143e-1f92-4817-a4ec-d4d6b7ef9dcf":"OBTAIN_N_BIRD_IN_A_WEEK",
// "d907621f-0dc0-462c-ac3a-bd2742323e5c":"WATCH_VIDEO_N_TIME",
// "302cd232-9e78-46a7-b5e4-043ea8aa830d":"COLLECT_N_PET",
// "90b2cba7-a064-459b-8caf-e3369583b886":"LEVEL_UP_PET1_N_TIME",
// "1148ec3c-237f-4617-8631-2fe56d6a1105":"LEVEL_UP_PET2_N_TIME",
// "1e22d9d3-21ec-4d47-b10e-f53d5f98cd62":"LEVEL_UP_PET3_N_TIME",
// "94f9d263-795e-4a0b-8133-8be0b78f927d":"LEVEL_UP_PET4_N_TIME",
// "7851e198-3ee0-4ab6-bf8f-3cfb2df617f3":"LEVEL_UP_PET5_N_TIME",
// "e42add1f-f61f-4c92-a6ba-ee3059bda397":"LEVEL_UP_PET7_N_TIME",
// "d732edaf-d94c-450c-bd29-2a6921aa3b97":"LEVEL_UP_PET8_N_TIME",
// "d236064f-0783-45ae-a37e-2f3f93f6d6d9":"LEVEL_UP_PET9_N_TIME",
// "abae2b56-6f6c-4414-aafb-d6d99d60e184":"LEVEL_UP_PET10_N_TIME",
// "e1918ba4-775d-490b-8473-9e71dfce5095":"LEVEL_UP_PET11_N_TIME",
// "57d53d96-7905-4a1b-b730-afb3b47158b4":"LEVEL_UP_PET12_N_TIME",
// "093d713b-a791-4748-8166-10469dec05ff":"LEVEL_UP_PET13_N_TIME",
// "0a585b49-9659-45a3-acf7-27b69fd62a01":"LEVEL_UP_PET14_N_TIME",
// "c86d8ce1-823b-41b7-9d0b-2f1b35f33574":"LEVEL_UP_PET15_N_TIME",
// "299af923-d03c-4ca8-b4ce-3a7d944f1772":"LEVEL_UP_PET16_N_TIME",
// "3c2789b3-f054-45fc-9177-8cb61db1f7c2":"LEVEL_UP_PET17_N_TIME",
// "04db542f-2523-472d-be46-89d11d25e0ab":"LEVEL_UP_PET18_N_TIME",
// "11cd6b09-8b73-435a-9392-de2e960704d0":"LEVEL_UP_PET19_N_TIME",
// "d852f56b-bb47-444c-ada9-3a2cb9225d44":"LEVEL_UP_PET20_N_TIME",
// "e68f3cdb-6b53-4fc2-84a5-de6051971ecd":"LEVEL_UP_PET21_N_TIME",
// "2d235b2d-6238-495f-a2aa-c3a28c29faba":"LEVEL_UP_PET22_N_TIME",
// "19ed44c1-dda5-431e-af5c-ef63688a2ec0":"LEVEL_UP_PET23_N_TIME",
// "38a23711-dad6-416b-8e31-ccf523bab432":"LEVEL_UP_PET24_N_TIME",
// "6fecc1b2-2893-4b6d-8387-e93093be0054":"LEVEL_UP_PET25_N_TIME",
// "365191ff-2358-4513-a8b0-1b42276bdaf9":"LEVEL_UP_PET26_N_TIME",
// "5ef80854-cecf-4770-b304-3c839a104363":"LEVEL_UP_PET27_N_TIME",
// "fe1136d2-5b96-41d7-bf9a-a960447a0979":"LEVEL_UP_PET28_N_TIME",
// "4a3ebc5e-a060-48d3-bb59-fe40f1ead0e8":"LEVEL_UP_PET29_N_TIME",
// "da55116a-ddd1-4fb5-b595-364211f7a22e":"LEVEL_UP_PET30_N_TIME",
// "acc42968-99fe-4abb-a411-6ee0cda3b059":"LEVEL_UP_PET31_N_TIME",
// "e431ab75-56f1-4b70-96ac-7b3a4bc4abb5":"LEVEL_UP_PET32_N_TIME",
// "5139f12e-39ab-4d12-81c3-cdcd3ecbb115":"LEVEL_UP_PET33_N_TIME",
// "21878dd3-5647-4c87-8993-c0ec1db276f3":"LEVEL_UP_PET34_N_TIME",
// "ac8999ae-ab0a-4ec6-a907-b38f0e04149d":"LEVEL_UP_PET35_N_TIME",
// "a7366cf7-8b41-4a24-a6a8-3a2952f689db":"LEVEL_UP_PET36_N_TIME",
// "8af9131f-4543-4a38-9cd7-28b5a82ef4a1":"LEVEL_UP_PET37_N_TIME",
// "f04108a0-e7a6-4d37-986b-73950ee82ec7":"LEVEL_UP_PET38_N_TIME",
// "da6d4b7d-41bc-4b64-8f7d-5b8d621db6bc":"LEVEL_UP_PET39_N_TIME",
// "c6292263-debf-4078-bb88-5087d3588a0e":"LEVEL_UP_PET40_N_TIME",
// "fe9cc4ff-3666-4921-949b-dc7dfd777fcb":"UPGRADE_N_PET_TO_LEVEL",
// "e3c482fd-21ea-4e47-9f14-987dbd993e36":"UPDATE_A_PET",
// "fe17c21c-7062-4620-be6e-243dd736b578":"UPDATE_N_PET",
// "681bec6d-d9fc-4ca4-b5f6-2d65f808a8dd":"UPDATE_A_PET_N_TIME",
// "01fcf719-4672-4f8c-a801-c66941d0185f":"UPGRADE_ALL_PET_TO_LEVEL",
// "97e9eed8-a0b2-4b1b-b6e7-3604b2df182d":"PLAY_GAME_N_TIME",
// "82cc2abe-317f-4124-8edf-e01061514601":"PLAY_GAME_ON_WEEKEND",
// "af5e8f05-d49f-447e-9522-3d814f3eb036":"COME_BACK_N_DAY_CONSECUTIVELY",
// "7fa100ef-f656-48f3-a7a5-dd5949e90c0a":"COMPLETE_FIRST_MISSION",
// "c2372ed5-55a3-4f7a-bfa0-b6f68f66fbe3":"COMPLETE_N_MISSION",
// "2341a9e4-27fd-4d9e-bc99-e68a3c067550":"CLEAR_N_BOSS",
// "d798bd0a-1608-4bb5-b2f4-1527e11d9f71":"PASS_N_BOSS_CONSECUTIVELY",
// "e65dd591-721e-4dae-8957-abb6ebe48e8c":"FAIL_N_TIME",
// "dc87e098-8353-414f-91e9-a32088c2443d":"MAKE_N_COMBO_VS_BOSS_REV",
// "b3dc258c-abc1-4cff-9210-38facb2b00c5":"MAKE_N_COMBO_VS_BOSS",
// "2450d6ee-ad2b-44a6-802e-637bb73e08ed":"MAKE_N_COMBO_VS_BOSS_BIG",
// "c82f5a88-5789-47b5-ba8f-683f7d105d93":"MAKE_N_COMBO_HIT",
// "f2646313-345e-4522-8249-de94deedced6":"SWIPE_N_RUNE_IN_ONE_HOR_N_TIME",
// "4cd8b3dd-ad90-42b2-bd60-6f65cda25814":"SWIPE_N_RUNE_IN_ONE_VERT_N_TIME",
// "0e9c84ae-d1dd-422e-8162-d31b5821eaa7":"SWIPE_N_RUNE_IN_ONE_ROW_N_TIME",
// "bc272a71-f72d-4460-ab49-46ae4b02a41e":"COLLECT_BIRD_DIAMOND_N_TIME",
// "eec93c87-fe1b-4414-a394-4ac6a809d401":"COLLECT_N_DIAMOND",
// "24450ed0-2ead-4753-b396-ef2dcd9fc2b4":"REVIVE_ALL_TIME_N_TIME",
// "3948c4fc-96b5-41f9-9c06-8376cdac5c16":"REVIVE_N_TIME_A_HERO",
// "9578bbb4-e712-45a9-8023-9d80b6afd6d0":"MAKE_N_HERO_REVIVE",
// "8224ed62-3732-47a2-a3d7-aa299c74beb8":"REVIVE_N_TIME_A_SUPPORT",
// "4b852055-a1c0-419f-8f9c-44d5e6870966":"MAKE_N_SUPPORT_REVIVE",
// "10ab7c0c-46ad-49c7-a966-c862f2239bd0":"THUNDER_ATTACK_N_TIME",
// "6ad91c6d-df41-477c-85ae-2fbb1b4dad61":"TWINSHADOW_ATTACK_N_TIME",
// "acf6ea55-76ba-476e-ab79-8ad6c66425fa":"LASTHAND_ATTACK_N_TIME",
// "c2590d94-e4ed-422b-9dd2-c5e9216761e4":"FASTANDFURIOUS_ATTACK_N_TIME",
// "6755e732-7223-4140-9414-f94215a144d7":"ANGEROFGOD_ATTACK_N_TIME",
// "4e6d072e-e8f7-45a9-917c-5660b3102050":"GOLDFINGER_ATTACK_N_TIME",
// "c61b3851-123a-48fe-bcf4-e1a5b2fddab2":"PETALLIANCE_ATTACK_N_TIME",
// "f6d533d9-7b48-4f80-8fa8-b77b25abe955":"SHIELDOFGOD_ATTACK_N_TIME",
// "10c3f171-5fa4-47fc-858f-d9987d1db678":"FLYSUPPORT_ATTACK_N_TIME",
// "591e7601-6345-4f38-862c-7b5e937d27d7":"TEAMMATE_ATTACK_N_TIME",
// "1605a74b-f140-4357-850f-be8709f9526c":"USE_N_TIME_N_POWER_UP",
// "2c478a7f-cebe-485c-919f-00d307df6e20":"START_USE_MANA",
// "9ebabd53-e47e-4b63-8982-bd787002f2e5":"COMBINE_N_POWER_UP_SAME_TIME",
// "add31261-48f9-4369-a099-c4b3680814eb":"USE_SHIELD_N_TIME",
// "bbef5678-1f71-464c-be56-650761694c97":"FIRST_TIME_WORLD_LOOP",
// "7e0a58cd-cf2a-42ea-a311-cc9ec0e505a3":"MAKE_N_WORLD_TOUR",
// "d444b105-323b-46f4-9eae-ea222adc2280":"USE_N_TIME_N_PERK",
// "dd6b3b02-f55f-4a21-b5f9-558713f69d00":"USE_GIVEMECASH_N_TIME",
// "5990365a-35a1-4269-a614-a38becdab9e9":"USE_GIVEMECASH_ONCE",
// "8b1ca2a8-6591-4318-8f4c-b36f07633f47":"USE_POWEROFHOLDING_N_TIME",
// "a7e43111-e062-46f1-b6a0-3bea44ea565f":"USE_POWEROFHOLDING_ONCE",
// "68210f68-1fec-4ddd-a43b-e8eaf38e805c":"USE_DOOM_N_TIME",
// "9fec3aa0-8c0b-4643-98c7-859ed0536164":"USE_DOOM_ONCE",
// "f40488a8-373a-45ae-a434-b755bc086e07":"USE_MANAPORTION_N_TIME",
// "4729bfed-ba4f-49fc-ab6a-20f989d492a5":"USE_MANAPORTION_ONCE",
// "e6ab73d4-e73a-4637-910b-b6643ef07b5f":"CRITICAL_HIT_N_TIME",
// "05e285c7-b5b5-404c-9193-4f27a3bd7e6d":"TAP_N_CRITICAL_IN_DURATION",
// "1c9aa4ef-a649-423b-972e-847995e787d8":"PLAY_TIME_IN_MINUTE",
// "198af832-577d-4d4a-afd6-dfaa4c2639fa":"PLAY_TIME_IN_HOUR",
// "15432b4d-5622-4a8c-a20d-9dec67479ce2":"PLAY_TIME_IN_DAY",
// "5e2a9187-55b1-4eb8-bc5f-c416ebfe96e8":"REDUCE_GHOST_ROUND_N_TIME",
// "ce22f359-69fd-4f73-8848-4cced2be6f4c":"REACH_THRESHOLD_N_DPS",
// "da1436f0-3b1c-47d0-ac1d-2aafe9659282":"USE_N_RED_RUNE",
// "00fae54e-35c6-48e8-a969-bf5a00ee867a":"USE_N_SWORD_RUNE",
// "930ed5a8-d0cb-452a-8ead-599b9e7eb967":"USE_N_HEALTH_RUNE",
// "63a2d509-0d87-4225-9206-f68a4769f51b":"USE_N_BLUE_RUNE",
// "f19e09c6-6810-4fa0-acd1-2433c4a773be":"USE_N_ORANGE_RUNE",
// "31e5644d-6ad2-451d-bf15-4c1979318eaf":"USE_N_PURPLE_RUNE",
// "2cac4319-c1ca-4f0d-b1be-a67d40f86cf0":"SHARE_FACEBOOK",
// "b5aa330d-5f0a-47d0-a883-939e7befd6cf":"SHARE_TWITTER",
// "ef871708-e031-4109-955d-98c12cc5d983":"PLAY_N_MASTERMIND_DURING_DAY",
// "b035eda8-d018-4863-8dc7-b3c313bc6f0e":"PLAY_N_MASTERMIND",
// "06f79ed0-51ec-42ae-ba1c-60b36c11f834":"REACH_N_MASTERMIND_COLOR_IN_AROW",
// "fdd308a2-b0ee-4093-b7ff-5c9f5793bd60":"WIN_N_MASTERMIND",
// "14ca15a0-1a36-40c0-8bce-d8b1dd671ff8":"MAKE_N_QUEST",
// "ff987382-dfb4-474c-82fc-cffb494d944b":"UNLOCK_WORLD_NAME"}


let QuestTap = require('./QuestTap');
let QuestCollectGold = require ('./QuestCollectGold');
let QuestDefaultStrategy = require('./QuestDefaultStrategy');
let QuestDefaultFarming = require('./QuestDefaultFarming');
class QuestStrategyFactory {
//TODO on drop le quest code, on check le json, on g�n�re la cl�, on invoque le mapping, on r�cup�re le coding.
    // ou on return null.
     static getInstance(questJson, questContext) {
        let questCode = null;
        if (questJson) {
            //questCode = questIdReference[questJson.id];
            questCode = questJson.ordered[0].progressType + '_*_' + questJson.ordered[0].eventName;
        }
        switch (questCode) {
            case 'heroUpdateLevel_*_count_all_heroes_reach_rank':  {
                return null;
            }

            case 'TAP_N_TIME' : {
                return new QuestTap(questContext);
            }
            case 'COLLECT_N_GOLD' : {
                return new QuestCollectGold(questContext);
            }
            case 'KILL_N_GHOST' : {
                return new QuestDefaultFarming(questContext);
            }
            default: {
                return new QuestDefaultStrategy(questContext);
            }
        }
    }
}

module.exports = QuestStrategyFactory
