<template>
    <v-app>
        <questList :pQuestList="this.questList" :should-init="true" @close="close()"/>



<!--        <iconGridSelection   :skillData="selectedSupport" :icons="pIconList" :color="supportColor"-->
<!--                             :modal="false" @closeModal="doCloseSelection()" @selectIcon="onUpdatedNewIcon"-->
<!--                             @changeColor="changeColor"-->



<!--        />-->



        <v-footer app>
            <!-- -->

            <v-row>
                <v-col align="right">
                    <router-link  style="text-decoration: none; color: inherit;"
                                  to='/'>
                        <v-icon
                                color=red>
                            mdi-exit-to-app
                        </v-icon>
                    </router-link>
                </v-col>
            </v-row>


        </v-footer>
    </v-app>
</template>

<script>

    //import iconGridSelection from './components/IconGridSelection.vue'
    import questList from './components/QuestList.vue';

    export default {
        components: {
            //'iconGridSelection': iconGridSelection,
            'questList':questList
        },

        props:["pSkillList","pIconList","pQuestList"],
        data () {

            return {
                selectedSupport : this.pSkillList?this.pSkillList[0]:{},
                iconList: [],
                questList: this.pQuestList?this.pQuestList:[],
                supportColor: 'green',
                show: false,
            }
        },

        created : async function() {
          //console.log('Quest Component created will check the props');
          //console.log(this.pSkillList);
          //console.log('Quest prop pQuestList');
          //console.log(this.pQuestList);

          this.selectedSupport = this.pSkillList?this.pSkillList[0]:null;

        },

        updated: function() {
            //console.log('Quest updated');
            //console.log(this.pSkillList);

        },

        methods: {
            doCloseSelection :function() {

            },
            changeColor: function() {

            },
            onUpdatedNewIcon: function() {

            }
        }
    }

</script>
