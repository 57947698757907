//let SMPGameplay = require('../SMPGameplay');
let QuestDefaultStrategy = require('./QuestDefaultStrategy');
let SMPNum = require('../SMPNum');

class QuestTap extends QuestDefaultStrategy{

    constructor(questContext) {
        super(questContext);
    }

    initStartingValue () {
        //TODO: must init the value with the proper value of the first progress. i guess.
        this.questContext.KPIGameplayBossKilled =10;
        this.questContext.KPIGameplayBossLevel =1;

    }

    increaseKPIGameplayReference() {
        this.questContext.KPIGameplayBossLevel += 5;
        this.questContext.challengeTarget += 5;
    }

    computeChallenge() {
        //Probl�me je me retoruve avec une d�pendance dans la strucuration du truc.
        this.questContext.challengeTarget = this.questContext.level * this.questContext.questJson.ordered[0].target;
    }

    computeReward() {
        let reward = SMPNum.multSmpNum(
            //SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            new SMPNum(this.questContext.KPIGameplayBossKilled));

        reward.setPower(Math.floor(reward._power));
        this.questContext.reward = reward;
    }
}

module.exports = QuestTap
