//let SMPGameplay = require('../SMPGameplay');
let SMPNum = require('../SMPNum');
let QuestDefaultStrategy = require('./QuestDefaultStrategy');

class QuestCollectGold extends QuestDefaultStrategy{

    constructor(questContext) {
        super(questContext);
    }

    initStartingValue () {
        // this.questContext.KPIGameplayBossKilled = 10;
        // this.questContext.KPIGameplayBossLevel =1;
        this.questContext.KPIGameplayBossKilled = parseInt(this.questContext.questJson.ordered[0].gameplayChallengeBossToKill);
        this.questContext.KPIGameplayBossLevel = parseInt(this.questContext.questJson.ordered[0].gameplayChallengeBossToKillStartingLevel);
    }

    increaseKPIGameplayReference(level) {
        this.questContext.KPIGameplayBossLevel += 100 ;
        //this.questContext.KPIGameplayBossKilled += 10;
        console.log(level);
    }

    computeChallenge(level) {
        //let moneyDropByBoss =  SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel);
        let moneyDropByBoss =  this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel);

        moneyDropByBoss.setPower(Math.ceil(moneyDropByBoss._power));
        let value = SMPNum.multSmpNum(moneyDropByBoss,new SMPNum(this.questContext.KPIGameplayBossKilled));

        // value = SMPNum.divSmpNum(value,new SMPNum(2));
        this.questContext.challengeTarget = value;
        console.log(level);
    }

    computeReward(level) {
        //let reward = SMPNum.multSmpNum(SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel), new SMPNum(this.questContext.KPIGameplayBossKilled));
        let reward = SMPNum.multSmpNum(this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel), new SMPNum(this.questContext.KPIGameplayBossKilled));
        reward.setPower(Math.floor(reward._power));
        this.questContext.reward = reward;
        console.log(level);
    }
}

module.exports = QuestCollectGold
