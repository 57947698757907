//let SMPGameplay = require('../SMPGameplay');
let SMPNum = require('../SMPNum');

class QuestDefaultStrategy {

    constructor(questContext) {
        this.questContext = questContext
        //this.typeChallenge = this.questContext.questJson.ordered[0].typeChallenge;

        if (this.questContext.questJson.ordered[0].typeChallenge === 'smpnum' || this.questContext.questJson.ordered[0].bigTarget) {
            this.typeChallenge = 'smpnum';
        }else {
            this.typeChallenge = 'int';
        }

        this.initStartingValue();
        this.initFirstChallenge();
    }

    initFirstChallenge() {

        //if (this.questContext.questJson) {
            if (this.typeChallenge ==='int') {
                this.questContext.challengeTarget = this.questContext.questJson.ordered[0].target;
            }else if (this.typeChallenge ==='smpnum') {
                let temp = new SMPNum(1);
                temp.setPower(this.questContext.questJson.ordered[0].bigTarget);
                this.questContext.challengeTarget = temp;
            }else {
                this.questContext.challengeTarget = this.questContext.questJson.ordered[0].target;
            }
        //}

    }

    initStartingValue () {
        this.questContext.KPIGameplayBossKilled = 10;
        this.questContext.KPIGameplayBossLevel =1;
        //this.questContext.challengeTarget = 10;
    }

    increaseKPIGameplayReference() {
        this.questContext.KPIGameplayBossLevel += 10;
        this.questContext.challengeTarget += 10;
        this.questContext.KPIGameplayBossKilled += 1;
    }

    computeChallenge() {

        if (this.typeChallenge === 'int') {
            this.questContext.challengeTarget = this.questContext.challengeTarget * this.questContext.level;
        }else {
            //let moneyDropByBoss =  SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel);
            let moneyDropByBoss =  this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel);
            this.questContext.challengeTarget = SMPNum.multSmpNum(moneyDropByBoss,new SMPNum(10));
        }

    }

    computeReward() {
        let reward = SMPNum.multSmpNum(
            //SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            new SMPNum(this.questContext.KPIGameplayBossKilled));

        reward.setPower(Math.floor(reward._power));

        this.questContext.reward = reward;
    }

}

module.exports = QuestDefaultStrategy
