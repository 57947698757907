
let QuestStrategyFactory = require('./questImpl/QuestStrategyFactory')

//let QuestConstants = require('./constants/QuestConstants');

class GameplayQuest {

    //ok l'éditeur ne doit pas me permettre d'ajuster la puissnace ça c'est débile
    //il faut que j'ajuste la référence de gameplay.

    constructor(questJson,balanceBuilder) {
        this.challengeTarget = null;
        this.reward = null;
        this.KPIGameplayBossKilled = null;
        this.KPIGameplayBossLevel = null;
        this.balanceBuilder = balanceBuilder;
        this.questJson = questJson;
        this.level = 1;
        this.questStrategy = QuestStrategyFactory.getInstance(this.questJson,this);

        this.questStrategy.initStartingValue(this.level);
        this.computeChallengeAndReward(this.level);
    }

    collect() {
        this.level = this.level + 1;
        this.questStrategy.increaseKPIGameplayReference(this.level);
        this.computeChallengeAndReward(this.level);
    }

    displayChallenge() {
        if (this.questJson.ordered[0].typeChallenge === 'smpnum' || this.questJson.ordered[0].bigTarget) {
            if (this.challengeTarget.ToReadableAlphabetV2) {
                return this.challengeTarget.ToReadableAlphabetV2();
            }else {
                return 'power: ' + this.challengeTarget;
            }

            //}else if (this.questJson.ordered[0].typeChallenge === 'int') {
        }  else {
            return this.challengeTarget;
        }
        // }else {
        //     return 'n/a';
        // }
    }

    computeChallengeAndReward() {
        this.questStrategy.computeChallenge(this.level);
        this.questStrategy.computeReward(this.level);
    }

    computeQuestEvolutionSimulation(nTimes) {
        //console.log('call: computeQuestEvolutionSimulation');

        let res = {};
        res.list = [];
        let numberOfTimesYouCanPlayTheQuest = nTimes?nTimes:10;
        for (let i = 0; i < numberOfTimesYouCanPlayTheQuest; i++) {
            let record = {level: this.level, KPIGameplayBossKilled:this.KPIGameplayBossKilled, KPIGameplayBossLevel:this.KPIGameplayBossLevel , challengeTarget: this.challengeTarget, reward: this.reward.ToReadableAlphabetV2()}
            res.list.push(record)
            this.collect();
        }

        return res;
    }

}

module.exports = GameplayQuest
