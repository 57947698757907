<template>



  <v-card
  >
      <v-row>
        <v-col
            cols="9"
            offset="6"
        >

            {{ getFilteredQuestList(this.questList).length }} unique Quests

            Cumulated Reward : {{ this.computeTotalReward(this.questList) }}

            Selected: {{ (this.selectedQuest && this.questList) ? getFullLabel(this.selectedQuest) : 'n/a' }}



            <v-btn
                class="mx-2"
                fab
                dark
                color="teal"
                small
                @click="showMe()"
            >
              <v-icon dark>
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>


        </v-col>

      </v-row>



    <v-divider></v-divider>

    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    ></v-text-field>


    <v-dialog
        v-model="showDetailQuest"
        scrollable
        @click:outside="close()"
    >
      <v-card>
        <v-card-title>Quest: {{ getSelectedQuestTitle()  }}</v-card-title>
        <v-data-table

            :headers="headersDetail"
            :items="detailQuest.list"

            class="elevation-1 petList"

            hide-default-footer

            :options="{itemsPerPage:200}"

            show-group-by
        >

          <template v-slot:item.challengeTarget="{item}">
            {{ getDetailQuestTargetValue(item) }}
          </template>

        </v-data-table>
      </v-card>
    </v-dialog>




    <v-card
        max-height="500" class="overflow-y-auto"
    >


    <v-row>

      <v-col>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="questList"
            class="elevation-1 questList"
            hide-default-footer
            :options="{itemsPerPage:500}"
            :search="search"
            show-group-by
            show-select

            @click:row="didClickOnRow"
        >

          <template v-slot:item.id="{item}">
            {{ getFullLabel(item,true) }}

            <v-edit-dialog

            >


              <template v-slot:input>
                    <pre>
                      {{item}}

                    </pre>



              </template>
            </v-edit-dialog>

          </template>



<!--          <template v-slot:item.iconName="{item}">-->
<!--            <icon-thumbnail :icon-name="item.iconName" />-->
<!--            <v-edit-dialog-->
<!--                :return-value.sync="item.iconName"-->
<!--            >-->
<!--              <template v-slot:input>-->
<!--                <IconQuestSelector />-->
<!--              </template>-->
<!--            </v-edit-dialog>-->
<!--          </template>-->

          <template v-slot:item.difficulty="{item}">

            <v-edit-dialog
                :return-value.sync="item.difficulty"
            >
              {{ item.difficulty }}

              <template v-slot:input>
                <v-select
                    v-model="item.difficulty"
                    v-on:change="updateSelectedDifficulty"

                    :items="[
                                                'easy and short','easy and long','normal and short','normal and long','hard and short','hard and long'
                                            ]"
                    label="Difficulty"
                ></v-select>

              </template>
            </v-edit-dialog>

          </template>

          <template v-slot:item.type="{item}">

            <v-edit-dialog
                :return-value.sync="item.type"
            >
              {{ item.type }}

              <template v-slot:input>
                <v-select
                    v-model="item.type"
                    v-on:change="updateSelected"



                   :items="[
  'ads',             'battle_boss',
                'big_boss',        'bird',
                'collect_diamond', 'collect_gold',
                'diamond_bird',    'dps',
                'failed_boss',     'game_launched',
                'game_level',      'ghost',
                'hero',            'master_mind',
                'math3',           'pass_boss',
                'passive_skill',   'perk',
                'play_time',       'power_up',
                'quest',           'revenge_boss',
                'social_share',    'support',
                'tap',             'team',
                'tutorial',        'unlock_pet',
                'unlock_zone',     'update_pet',
                'world'
                ]"
                    label="Category"
                ></v-select>

              </template>
            </v-edit-dialog>

          </template>


          <template v-slot:item.formulaType="{item}">

            <v-edit-dialog
                :return-value.sync="item.formulaType"
            >
              {{ item.formulaType }}

              <template v-slot:input>
                {{item.formulaType}}
                <v-select
                    v-model="item.formulaType"
                    :items="[
                                                'Arithmétique','Géométrique','KPI'
                                            ]"
                    label="Formule"
                ></v-select>

              </template>
            </v-edit-dialog>

          </template>

          <template v-slot:item.questTitleCode="{item}">
            {{ getFullLabel(item) }}
          </template>

          <template v-slot:item.target="{item}">
            <v-edit-dialog
                :return-value.sync="item.ordered[0].target"
            >

              <!--                  {{ item.ordered[0].target }}-->


              {{getFullLabelChallenge(item)}}



              <template v-slot:input>
                <!--                    <v-text-field-->
                <!--                        v-model="item.ordered[0].target"-->
                <!--                        label="Target"-->
                <!--                        single-line-->
                <!--                        autofocus-->
                <!--                        @focus="$event.target.select()"-->

                <!--                    ></v-text-field>-->

                {{item.ordered[0].progressType}}
                <!--                    <v-select-->
                <!--                        v-model="item.ordered[0].typeChallenge"-->
                <!--                        :items="[-->
                <!--                            {'text':'Numerique','value':'int' },-->
                <!--                            {'text':'Gameplay','value':'smpnum' }-->
                <!--                        ]"-->
                <!--                        label="Equivalent Jeu"-->
                <!--                    ></v-select>-->

                <!--                    <v-text-field-->
                <!--                        v-if="item.ordered[0].typeChallenge === 'int'"-->
                <!--                        v-model="item.ordered[0].target"-->
                <!--                        label="Value"-->
                <!--                        single-line-->
                <!--                        autofocus-->
                <!--                        @focus="$event.target.select()"-->

                <!--                    ></v-text-field>-->

                <!--                    <v-text-field-->
                <!--                        v-if="item.ordered[0].gameplayKpi == 'gameplay'"-->
                <!--                        v-model="item.ordered[0].gameplayRewardBossKilled"-->
                <!--                        label="Equivalent Boss Killed"-->

                <!--                    ></v-text-field>-->

                <!--                    "target": 20,-->
                <!--                    "duration": 2,-->
                <!--                    "bigReward": 1,-->
                <!--                    "progressType": "count_in_duration"-->



                <v-text-field
                    v-if="item.ordered[0].progressType === 'count_in_duration'"
                    v-model="item.ordered[0].duration"
                    label="Duration Value"
                ></v-text-field>

                <v-text-field
                    v-if="item.ordered[0].target"
                    v-model="item.ordered[0].target"
                    label="Target"
                ></v-text-field>


                <v-text-field
                    use_n_time_n_perk
                    v-if="item.ordered[0].target2"
                    v-model="item.ordered[0].target2"
                    label="Target2"
                ></v-text-field>


                <v-text-field
                    v-if="item.ordered[0].bigTarget"
                    v-model="item.ordered[0].bigTarget"
                    label="Big Target"

                ></v-text-field>

                <v-text-field
                    v-if="item.ordered[0].typeChallenge === 'smpnum' || item.ordered[0].bigTarget "
                    v-model="item.ordered[0].gameplayChallengeBossToKill"
                    label="GoldBoss to kill"

                ></v-text-field>

                <v-text-field
                    v-if="item.ordered[0].typeChallenge === 'smpnum' || item.ordered[0].bigTarget"
                    v-model="item.ordered[0].gameplayChallengeBossToKillStartingLevel"
                    label="GoldBoss to kill starting level"
                ></v-text-field>





              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.ordered="{item}">
            {{getRewardValue(item)}}
          </template>


        </v-data-table>
      </v-col>



<!--      <v-col v-if ="showIconQuestSelector">-->

<!--        <v-expand-transition>-->
<!--          <v-container class="sticky-top">-->
<!--            <IconQuestSelector />-->
<!--          </v-container>-->
<!--        </v-expand-transition>-->



<!--      </v-col>-->

    </v-row>

    </v-card>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn color="blue darken-1" text @click="ExportCSV()">Export</v-btn>
      <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
      <v-btn color="green darken-1" text @click="dispatchSave()">Save</v-btn>
      <v-btn color="yellow darken-1" text @click="filterDynamic()">
        {{ this.showDynamic ? "With Dynamic" : "No Dynamic" }}
      </v-btn>

    </v-card-actions>



  </v-card>



</template>

<style lang="scss">
.questList tbody {
  tr .standard:hover {
    background-color: darkgray !important;
  }
}

.standard {
  background-color: transparent;
}

.selected {
  background-color: dodgerblue !important;
}

</style>

<script>

//import deepCopy from '../util.js';
import lodash from 'lodash'
//import draggable from 'vuedraggable'

import GameplayQuest from '../../src/GameplayQuest';
import QuestConstant from '../../src/constants/QuestConstants';

//import IconThumbnail from './IconThumbnail';
//import IconQuestSelector  from "./IconQuestSelector";

let SMPNum = require("../SMPNum.js");

/*
"skippable": true,
"isDynamic": true,
"dynamicAvailable": -1,
 */

export default {
  data() {
    return {
      showDynamic: true,
      selectedQuest: null,
      showDetailQuest: false,
      showIconQuestSelector: true,
      search: "",
      questList: [],
      selected: [],
      headers: [
        {text: '_id', value: 'id',groupable: false},
        //{text: 'tuto', value: 'tutorialNum'},
        //{text: 'Main Title', value: 'questTitleCode'},
        {text: 'Objectives', value: 'questTitleCode',groupable: false},
        {text: 'Target 1', value: 'target',groupable: false},
        {text: 'KPI Boss Level', value: 'kpiBossLevel',groupable: false},
        //{text: 'KPI Boss Level Should Appears', value: 'kpiBossKilled',groupable: false},
        {text: 'KPI Boss Level Should Appears', value: 'kpiGameLevelShouldAppear',groupable: false},
        {text: 'Formula Type', value: 'formulaType'},
        {text: 'Icon Name', value: 'iconName',groupable: false},
        {text: 'Reward ', value: 'ordered',groupable: false},
        {text: 'Skip Price', value: 'skipPrice',groupable: false},
        // {text: 'Skippable ', value: 'skippable',groupable: false},
        // {text: 'isDynamic ', value: 'isDynamic',groupable: false},
        {text: 'category ', value: 'type'},
        {text: 'difficulty ', value: 'difficulty'},
        {text: 'dynamicAvailable ', value: 'dynamicAvailable',groupable: false},
        {text: 'isDaily ', value: 'isDaily'},
      ],
      headersDetail: [
        {text: 'Level', value: 'level', groupable: false},
        {text: 'Challenge', value: 'challengeTarget', groupable: false},
        {text: 'KPI Boss Level', value: 'KPIGameplayBossLevel', groupable: false},
        {text: 'KPI Boss Killed', value: 'KPIGameplayBossKilled', groupable: false},
        {text: 'Reward', value: 'reward', groupable: false}
      ]
    }
  },

  props: {
    'pQuestList': {
      type: Array,
      required: true
    },
    'shouldInit': {
      type: Boolean,
    }
  },


  computed: {
    detailQuest: function () {

      ////console.log(this.questList);
      let quest = this.selectedQuest;
      let listDetail = [];
      if (quest) {
        let gameplayQuest = new GameplayQuest(quest,this.$store.state.balanceBuilder);
        listDetail = gameplayQuest.computeQuestEvolutionSimulation(quest, this.getFullLabel);

        ////console.log(listDetail);
        ////console.log(quest);
      }
      //maintenant ce qu'il faut faire c'est faire une soufflet de list.
      //appeler une simulation d'event.


      return listDetail;
    }
  },

  methods: {

    showMe : function() {
      //console.log('i show you');
      this.showIconQuestSelector = ! this.showIconQuestSelector;
    },

    getFullLabelChallenge : function(item) {
      // Il faut ici déterminer le modèle qui va me permettre de paramétrer le comportement,
      //d'une quete sur le target
      /*
      *   {
          "progressTitleCode": 57,
          "eventName": "completeStage",
          "target": 25,
          "bigReward": 1,
          "progressType": "count_by_one"
        }
        *
        * 'progressTitleCode',
  'eventName',
  'bigTarget',
  'bigReward',
  'progressType',
  'label',
  'target',
  'targetUnit',
  'duration',
  'target2',
  'bossType',
  'fruitType',
  'supportId',
  'heroId',
  'petId',
  'powerUpType' ]

        *
        *
        *


        * unit : int/gameplay
        * target :
        * gameplay
        *  on a des kpi pour les target  gameplayTargetBossToKill
        *  on a des kpi pour les reward rewardBossKilled

      *
      * */



          let quest = new GameplayQuest(item,this.$store.state.balanceBuilder);
          return quest.displayChallenge();

      //return 'n/a';
    },


    getDetailQuestTargetValue : function(item) {
      if (item.challengeTarget.ToReadableAlphabetV2 ) {
        return item.challengeTarget.ToReadableAlphabetV2();
      }else {
        return item.challengeTarget;
      }

    },

    getRewardValue : function(item) {
      let questGameplay = new GameplayQuest(item,this.$store.state.balanceBuilder);
      return questGameplay.reward.ToReadableAlphabetV2();

    },

    getSelectedQuestTitle : function () {
      return this.selectedQuest?(this.selectedQuest.id):'n/a';
    },

    filterDynamic: function () {
      //how to filter the questList.
      this.showDynamic = !this.showDynamic;
    },

    getFilteredQuestList: function () {
      //console.log('get filtered quest list');
      let l = [];
      this.questList.forEach(quest => {
        if (quest.isDynamic === true) {
          if (this.showDynamic) {
            l.push(quest);
          }
        } else {
          l.push(quest);
        }
      })
      return l;
    },


    computeTotalReward: function (questList) {
      let finalReward = new SMPNum(0);
      questList.forEach(quest => {


        quest.ordered.forEach(progress => {
          //reward = reward + progress.bigReward;
          let bnReward = new SMPNum(0);

          if (!isFinite(progress.bigReward)) {
            progress.bigReward = 0;
            //console.log('THIS PROGRESS IS SHIT: ' + progress.progressTitleCode);
            ////console.log(progress);
          }
          bnReward.setPower(parseInt(progress.bigReward))
          finalReward = SMPNum.plus(finalReward, bnReward);
        })
      });
      return finalReward.ToReadableAlphabetV2();
    },

    getSelected: function (index) {
      return index === this.selectedQuest;
    },

    didClickOnRow: function (index) {
      //console.log('did click on row');
      //console.log(index);
      this.selectedQuest = index;
      //this.showDetail = true;
      this.showDetailQuest = true;

    },

    updateSelected : function(category) {
      this.selected.forEach(row => {
        row.category = category;
      })
    },
    updateSelectedDifficulty: function(difficulty) {
      this.selected.forEach(row => {
        row.difficulty = difficulty;
      })
    },

    close: function () {
      this.showDetail = false;
    },

    loadData: function (list) {
      this.questList = [];
      if (Array.isArray(list)) {
        list.forEach(e => {
          //console.log('load data')
          //let copy = deepCopy(e);

          //this.questList.push(Object.assign({}, e));
          this.questList.push(lodash.cloneDeep(e));
          //this.questList.push(copy);
        });
      }
    },

    didEndDragging: function (evt) {
      this.selectedQuest = evt.newIndex;
    },

    didChange: function () {
      ////console.log('did change SupportSkillViewer');
      this.loadData(this.pQuestList);
    },

    dispatchClose: function () {
      this.$emit('close');
    },


    ExportCSV: function () {
      //console.log(this.questList);

      let csvContent = "data:text/csv;charset=utf-8,";

      this.questList.forEach((rowArray) => {
        //console.log('I AM LOGING QUEST LIST');
        let label = this.getFullLabel(rowArray);
        //let row = label.join(",");
        csvContent += label + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);

    },

    dispatchSave: function () {
      //this.questList;
      this.$store.commit('pushQuests',this.questList);
    },


    getRewardLabel: function (progressList) {
      let finalReward = new SMPNum(0);
      //finalReward.setPower(1);

      progressList.ordered.forEach(progress => {
        //reward = reward + progress.bigReward;
        let bnReward = new SMPNum(0);

        if (!isFinite(progress.bigReward)) {
          progress.bigReward = 0;
          //console.log('THIS PROGRESS IS SHIT: ' + progress.progressTitleCode);
          //console.log(progress);
        }
        bnReward.setPower(parseInt(progress.bigReward))
        finalReward = SMPNum.plus(finalReward, bnReward);
      })

      //finalReward = new SMPNum(10);
      return finalReward.ToReadableAlphabetV2();
    },


    getQuestTitle: function (quest) {
      let fullLabel = "";

      ////console.log(quest);
      let i = 0;
      quest.ordered.forEach(progress => {
        if (i > 0) {
          fullLabel = fullLabel + ' and '
        }
        let progressTitle = this.$store.state.progressMainTitle[progress.progressTitleCode];

        //if (progressTitle) {
        progressTitle = progressTitle.replace('[n]', progress.target);
        progressTitle = progressTitle.replace('[t]', progress.duration);
        progressTitle = progressTitle.replace('[n2]', progress.target2);
        fullLabel = fullLabel + progressTitle;
        //}

        i++;
      })

      return fullLabel;
    }
    ,


    getFullLabel: function (quest,takeFromTitle) {
      let fullLabel = "";

      ////console.log(quest);

      let gameplayQuest = new GameplayQuest(quest,this.$store.state.balanceBuilder);

      ////console.log(gameplayQuest);
      let i = 0;
      quest.ordered.forEach(progress => {
        if (i > 0) {
          fullLabel = fullLabel + ' and '
        }
        let progressTitle = this.$store.state.progressTitle[progress.progressTitleCode];

        if (takeFromTitle) {
          progressTitle = this.$store.state.progressMainTitle[progress.progressTitleCode];
        }

        if (progress.eventName === 'tutorial') {
          progressTitle = this.$store.state.tutorialTitle[progress.progressTitleCode];
        }


        //if (progressTitle) {
        //progressTitle = progressTitle.replace('[n]', progress.target);

        //gameplayQuest.displayChallenge()

        progressTitle = progressTitle.replace('[n]', progress.target?progress.target:gameplayQuest.displayChallenge());
        progressTitle = progressTitle.replace('[t]', progress.duration);
        progressTitle = progressTitle.replace('[n2]', progress.target2);
        progressTitle = progressTitle.replace('[n2]', progress.target2);

        if (progressTitle.includes('[world_name]')) {
          let zoneLabel = QuestConstant.zoneName[progress.target.toString()];
          progressTitle = progressTitle.replace('[world_name]', zoneLabel);
        }

        if (progress.bossType !== null || progress.bossType !== undefined) {
          ////console.log(QuestConstant);
          ////console.log(QuestConstant.bossType);
          let bossTypeLabel = QuestConstant.bossType[progress.bossType];
          progressTitle = progressTitle.replace('[boss_type]',bossTypeLabel);
        }

        if (progress.eventName ==='onFruitMatch') {
          let runeLabel = QuestConstant.runes[progress.fruitType];
          progressTitle = progressTitle.replace('[fruit_type]',runeLabel);
        }

        if (progress.supportId != null || progress.supportId !== undefined) {
          let supportLabel = QuestConstant.supportName[progress.supportId.toString()];
          progressTitle = progressTitle.replace('[support_name]',supportLabel);
        }

        if (progress.heroId != null || progress.heroId !== undefined) {
          let label = QuestConstant.heroName[progress.heroId.toString()];
          progressTitle = progressTitle.replace('[hero_name]',label);
        }

        if (progress.progressType.includes('rank')) {
          let supportLabel = QuestConstant.rankName[progress.target];
          progressTitle = progressTitle.replace('[rank_title]',supportLabel);
        }

        if (progress.heroId !== null || progress.heroId !== undefined) {
          let label = QuestConstant.heroName[progress.heroId];
          progressTitle = progressTitle.replace('[hero_name]',label);
        }

        if (progress.petId !== null || progress.petId !== undefined) {
          let label = QuestConstant.petName[progress.petId];
          progressTitle = progressTitle.replace('[pet_name]',label);
        }

        if (progress.powerUpType !== null || progress.powerUpType !== undefined) {
          let label = QuestConstant.heroSkill[progress.powerUpType];
          progressTitle = progressTitle.replace('[powerup_name]',label);
        }

        fullLabel = fullLabel + progressTitle;
        //}

        i++;
      })

      return fullLabel;
    }

  },

  created: function () {
    this.loadData(this.pQuestList);
  },

  components: {
    //IconQuestSelector,
    //VSelectList
    //iconThumbnail: IconThumbnail
  },

  watch: {
    pQuestList: {
      deep: true,
      handler: 'didChange'
    },

    shouldInit: {
      handler: 'didChange'
    },

    shoudIgnoreDynamic: {
      handler: 'didChange'
    }
  },

}

</script>
