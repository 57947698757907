//let SMPGameplay = require('../SMPGameplay');
let SMPNum = require('../SMPNum');
let QuestDefaultStrategy = require ('./QuestDefaultStrategy')

class QuestDefaultFarming extends QuestDefaultStrategy {

    constructor(questContext) {
        super(questContext);
    }

    initStartingValue () {
        this.questContext.KPIGameplayBossKilled =1;
        this.questContext.KPIGameplayBossLevel =5;
    }

    increaseKPIGameplayReference(level) {
        //l� il faut que l'on permette la s�quencee. il manque le leve.
        //il faut augmenter le boss level et la sequence,
        //on a une sequence de 3 par rapport au niveau.
        let sequenceIndex = level % 3;
        let kpiGamePlayBossToKills = -1;
        switch(sequenceIndex){
            case 1 :
                kpiGamePlayBossToKills = 5;
                break;
            case 2 :
                kpiGamePlayBossToKills = 3;
                break;
            case 0 :
                kpiGamePlayBossToKills = 1;
                break;
        }
        if (this.questContext.KPIGameplayBossLevel === 5){
            this.questContext.KPIGameplayBossLevel = 10;
        }else {
            this.questContext.KPIGameplayBossLevel += 10;
        }
        //this.questContext.challengeTarget = kpiGamePlayBossToKills;
        this.questContext.KPIGameplayBossKilled = kpiGamePlayBossToKills;
       //console.log(kpiGamePlayBossToKills);
    }

    computeChallenge(level) {
        //Probl�me je me retoruve avec une d�pendance dans la strucuration du truc.
        //this.questContext.challengeTarget = this.questContext.level * this.questContext.questJson.ordered[0].target;
        let sequenceIndex = level % 3;
        let target = 0;
        switch(sequenceIndex){
            case 1 :
                target = 20;
                break;
            case 2 :
                target = 50;
                break;
            case 0 :
                target = 100;
                break;
        }
        this.questContext.challengeTarget = target;
    }

    computeReward() {
        let reward = SMPNum.multSmpNum(
            //SMPGameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            this.questContext.balanceBuilder.gameplay.DropCoins(this.questContext.KPIGameplayBossLevel),
            new SMPNum(this.questContext.KPIGameplayBossKilled));

        //reward.setPower(Math.ceil(reward._power));
        this.questContext.reward = reward;
    }

}

module.exports = QuestDefaultFarming
